import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

// Utilities
import kebabCase from "lodash/kebabCase"
import Quotes from "../components/quotes"
import { starReplace } from "../components/functions"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  //The next logic is to support a comma-delimited score: totalscore,frontnine
  const totalscorearray = post.frontmatter.score.split(',');

  const autopost = `<p><span style=font-style:italic>Auto-generated text</span><br />
  It appears that there was nothing memorable about this round.</p>`
 
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p style={{ backgroundColor: "#dddddd", paddingLeft: "10px"}}>{post.frontmatter.date}</p>
        </header>
        {post.frontmatter.score && post.frontmatter.score !== "N/A" &&
        <div className={`scorebox ${totalscorearray[0] < data.site.siteMetadata.cutoffScore ? 'good' : 'bad'}`}>
          {totalscorearray[1] && <span className="nines">{totalscorearray[1]}</span>}
          {totalscorearray[0]}
          {totalscorearray[1] && <span className="nines">{totalscorearray[0] - totalscorearray[1]}</span>}
        </div>
        }
        <section
          dangerouslySetInnerHTML={{ __html: !!(post.html) ? post.html : autopost }}
          itemProp="articleBody"
        />
          {!/(jpg)|(jpeg)|(png)/.test(post.html) && 
            <Quotes />
          }
        <table className="courseInfo">
          <tbody>
          <tr>
            {post.frontmatter.courseurl &&
            <td>
              <a href={post.frontmatter.courseurl} target="_blank" rel="noreferrer">Website</a>
            </td>
            }
            {post.frontmatter.coursegps &&
            <td>
              <a href={`https://www.google.com/maps/@${post.frontmatter.coursegps},17z`} target="_blank" rel="noreferrer">Location</a>
            </td> 
            } 
            <td>
              <Link to={`/courses/${kebabCase(post.frontmatter.title)}/`}>Times Played</Link>
            </td>
          </tr> 
          </tbody>  
        </table>
        {post.frontmatter.tags &&
        <ul className="tag-list">
        {post.frontmatter.tags.map(tag => (
          <li key={tag}>
            <Link to={`/tags/${kebabCase(tag)}/`}>
              {/[1-5]star$/.test(tag) ? starReplace(tag) : tag}
            </Link>
          </li>
        ))}
      </ul>
      }
      <hr />
      </article>
      <nav className="blog-post-nav">
        <ul
          className="flexcontainer nearbyposts"
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        cutoffScore
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "dddd MMMM DD, YYYY")
        description
        score
        courseurl
        coursegps
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
