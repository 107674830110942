export const quotes =  [
  {
      "author": "Bobby Jones",
      "quote": "You swing your best when you have the fewest things to think about."
  },
  {
      "author": "Sam Snead",
      "quote": "Forget your opponents; always play against par."
  },
  {
      "author": "Sam Snead",
      "quote": "Nobody asked how you looked, just what you shot."
  },
  {
      "author": "Lee Trevino",
      "quote": "You don’t know what pressure is until you play for five bucks with only two bucks in your pocket."
  },
  {
    "author": "Sam Snead",
    "quote": "Of all the hazards, fear is the worst."
  },
  {
    "author": "Mark Twain",
    "quote": "Golf is a good walk spoiled."
  },
  {
    "author": "Sam Snead",
    "quote": "If a lot of people gripped a knife and fork the way they do a golf club, they’d starve to death."
},
{
    "author": "Harvey Penick",
    "quote": "Golf tips are like Aspirin: One may do you good, but if you swallow the whole bottle you’ll be lucky to survive."
},
{
    "author": "Bobby Jones",
    "quote": "I never learned anything from a match that I won."
},
{
    "author": "Nick Faldo",
    "quote": "Tempo is the glue that sticks all elements of the golf swing together."
},
{
    "author": "Payne Stewart",
    "quote": "A bad attitude is worse than a bad swing."
},
{
    "author": "Greg Norman",
    "quote": "Happiness is a long walk with a putter."
},
]