/**
 * Quotes Box
 */

import * as React from "react"
import { quotes } from "../data/quotes";

const randomNumber = Math.floor(Math.random() * quotes.length);

const Quotes = () => {
 
   // Whatever banner* files are in the images dir will get counted into here
   //let randomBannerNumber = Math.floor(Math.random() * data.allFile.totalCount)
   //const bannerImage = getImage(data.allFile.edges[randomBannerNumber].node.childImageSharp)
   //const bannerAlt = "Home Page " + data.allFile.edges[randomBannerNumber].node.name

   return (
    <div className="quotes-box">
      <div className="quotes-wrapper">
      <div className="quotes-text"><hr />{quotes[randomNumber].quote}</div>
      <div className="quotes-text author">- {quotes[randomNumber].author}<hr /></div>
      </div>
    </div>
   )
 }

 
export default Quotes
